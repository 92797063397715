import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { graphql, Link, useStaticQuery } from "gatsby"
import {FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube} from "react-icons/fa"
import Sitemap from "./sitemap"
import Email from "./email"
import Phone from "./phone"
import {Button} from "react-bootstrap";
import {RichText} from "prismic-reactjs";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      prismicGeneral {
        data {
          top_navigation {
            link {
            
              url
            }
            link_label
          }
          company_name {
            raw
          }
          bottom_navigation {
            link {
              url
            }
            link_label
          }
          email_adresse
          facebook
          linkedin
          instagram
          telefon
          footer_cta {
            url
          }
          footer_cta_label
          twitter
          youtube
          adresse {
            raw
          }
          impressum_link {
            url
          }
          impressum_link_label
          datenschutz_link {
            url
          }
          datenschutz_link_label
          agb_link {
            url
          }
          agb_link_label
        }
      }
    }
  `);

  const document = data.prismicGeneral.data;

  return (
    <footer>
      <Container>
        <Row>
          <Col className="d-flex flex-column align-items-stretch">
            <div className="d-flex justify-content-center mb-4">
              <Link to="/">
                <div className="footer-title d-flex flex-column align-items-center">
                  <h1>Inspirations<strong>zone</strong></h1>
                  {/*<h1 className="mb-0">{RichText.asText(document.company_name.raw)}</h1>*/}
                </div>
              </Link>
            </div>
            <Sitemap/>
            <div className="text-center">
              <p className="mb-4">
                {RichText.asText(document.adresse.raw)}<br/>
                <Email email={document.email_adresse}/>
                <br/>
                <Phone phone={document.telefon}/>
              </p>
              <Link to={document.footer_cta.url}>
                <Button size="sm" className="mb-3">{document.footer_cta_label}</Button>
              </Link>
              <div className="mb-3 d-flex justify-content-center">
                { document.facebook && <a className="social-link mx-2" href={document.facebook} target="_blank" rel="noreferrer"><FaFacebook/>&#8203;</a>}
                { document.instagram && <a className="social-link mx-2" href={document.instagram} target="_blank" rel="noreferrer"><FaInstagram/>&#8203;</a>}
                { document.twitter && <a className="social-link mx-2" href={document.twitter} target="_blank" rel="noreferrer"><FaTwitter/>&#8203;</a>}
                { document.youtube && <a className="social-link mx-2" href={document.youtube} target="_blank" rel="noreferrer"><FaYoutube/>&#8203;</a>}
                { document.linkedin && <a className="social-link mx-2" href={document.linkedin} target="_blank" rel="noreferrer"><FaLinkedin/>&#8203;</a>}
              </div>
              <p>
                Copyright © {new Date().getFullYear()} {RichText.asText(document.company_name.raw)}
                <br/>
                {document.impressum_link && <><Link to={document.impressum_link.url}>{document.impressum_link_label}</Link></>}
                {document.datenschutz_link && <>{' | '}<Link to={document.datenschutz_link.url}>{document.datenschutz_link_label}</Link></>}
                {document.agb_link && <>{' | '}<Link to={document.agb_link.url}>{document.agb_link_label}</Link></>}
              </p>
              <p className="small">
                Gestaltung und Umsetzung: <a href="https://martensigwart.com" target="_blank" rel="noreferrer">Marten Sigwart</a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

Footer.propTypes = {
}

Footer.defaultProps = {
}

export default Footer
