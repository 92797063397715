import React, {useEffect, useRef, useState} from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import { graphql, useStaticQuery, Link } from "gatsby"
import { globalHistory as history } from "@reach/router"
import {RichText} from "prismic-reactjs";

const Header = () => {

  const { location } = history;

  const ref = useRef(null);
  const [isSticky, setSticky] = useState(false);
  const [isExpanded, setExpanded] = useState(false);

  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top < 0);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  const handleToggle = (expanded) => {
    setExpanded(expanded);
  }

  const data = useStaticQuery(graphql`
    query SiteMenuQuery {
      file(relativePath: {eq: "logo.svg"}) {
        publicURL
      }
      prismicGeneral {
        data {
          company_name {
            raw
          }
          top_navigation {
            link {
              url
              slug
              uid
            }
            link_label
          }
        }
      }
    }
  `);
  const navigation = data.prismicGeneral.data.top_navigation;
  return (
    <header ref={ref}>
      <Navbar expand="lg" className={`${isSticky ? 'navbar-sticky' : ''} ${isExpanded ? 'expanded' : ''}`} onToggle={handleToggle}>
        <Link to="/" className={`navbar-brand d-flex align-items-center brush brush-right`}>
          {/*<div className="navbar-brand-img mx-2 brush brush-contain brush-center">*/}
          {/*  <Image src={data.file.publicURL} width={50} height={'auto'}/>*/}
          {/*</div>*/}
          Inspirations<strong>zone</strong>
          {/*{RichText.asText(data.prismicGeneral.data.company_name.raw)}*/}
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto text-center">
            { navigation.map((navItem, index) => {
              const isActive = location.pathname.includes(navItem.link.url)
              return (
                <Link key={`link-${index}`} to={navItem.link.url}>
                  <div className={`nav-link ${isActive ? 'active' : ''}`}>
                    {navItem.link_label}
                  </div>
                </Link>
              );
            })}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}

Header.propTypes = {
}

Header.defaultProps = {
}

export default Header
