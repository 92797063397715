import * as React from "react"
import Button from "react-bootstrap/Button";
import {graphql, useStaticQuery} from "gatsby";
import RichText from "prismic-reactjs/src/Component";
import GatsbyLink from "./gatsby-link";
import PropTypes from "prop-types"

const CookieConsent = (props) => {
  const data = useStaticQuery(graphql`
    query CookieConsentQuery {
      prismicGeneral {
        data {
          cookie_consent_message {
            raw
          }
          cookie_consent_title
          cookie_consent_button_accept
          cookie_consent_button_decline
        }
      }
    }
  `);
  const cookieConsent = data.prismicGeneral.data;
  return (
    <div className={`cookie-consent-dialog small ${!props.hidden ? 'show' : ''}`}>
      <div className="cookie-consent-header d-flex flex-row justify-content-between align-items-center">
        <h1>{cookieConsent.cookie_consent_title}</h1>
        {/*<button type="button" className="close" aria-label="Close" onClick={props.onDismiss}>*/}
        {/*  <span aria-hidden="true">&times;</span>*/}
        {/*</button>*/}
      </div>
      <div className="cookie-consent-body">
        <RichText render={cookieConsent.cookie_consent_message.raw} serializeHyperlink={GatsbyLink}/>
      </div>
      <div className="cookie-consent-footer d-flex flex-column flex-sm-row flex-sm-wrap justify-content-center justify-content-sm-end">
        <Button variant="secondary btn-sm"
                className="mb-2"
                disabled={props.hidden}
                onClick={props.onDecline}>{cookieConsent.cookie_consent_button_decline}</Button>
        <Button variant="primary btn-sm"
                className="mb-2 ml-0 ml-sm-3"
                disabled={props.hidden}
                onClick={props.onAccept}>{cookieConsent.cookie_consent_button_accept}</Button>
      </div>
    </div>
  )
}

CookieConsent.propTypes = {
  hidden: PropTypes.bool,
  onAccept: PropTypes.func,
  onDecline: PropTypes.func,
  onDismiss: PropTypes.func
}

CookieConsent.defaultProps = {
  hidden: true,
  onAccept: () => console.log('Cookies accepted'),
  onDecline: () => console.log('Cookies declined'),
  onDismiss: () => console.log('Cookie Banner dismissed with no action taken')
}

export default CookieConsent
