import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

const Sitemap = () => {
  const data = useStaticQuery(graphql`
    query SitemapQuery {
      prismicGeneral {
        data {
          top_navigation {
            link {
              url
            }
            link_label
          }
          bottom_navigation {
            link {
              url
            }
            link_label
          }
        }
      }
    }
  `);
  const document = data.prismicGeneral.data;
  const bottomNav = document.bottom_navigation.filter(nav => nav.link.url !== "");
  const topNav = document.top_navigation.filter(nav => nav.link.url !== "");
  const navigation = bottomNav.length ? bottomNav : topNav;

  return (
    <div className="d-flex flex-wrap justify-content-around mb-4">
      {navigation.map((navItem, index) => (
        <div className="sitemap-block m-2 text-center" key={`link-${index}`}>
          <Link to={navItem.link.url}>
            <h2>
              {navItem.link_label}
            </h2>
          </Link>
        </div>
      ))}
    </div>
  );
}

Sitemap.propTypes = {
}

Sitemap.defaultProps = {
}

export default Sitemap;
