/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"

import './theme.scss';
import SEO from "./seo";
import Container from "react-bootstrap/Container";
import CookieConsent from "./cookie-consent";
import {useCookies} from "react-cookie";
import {graphql, useStaticQuery} from "gatsby";

const Layout = ({ children, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  const INSPIRATIONSZONE_ANALYTICS_CONSENT = 'inspirationszone_analytics_consent';
  const [cookies, setCookie,] = useCookies();
  const analyticsConsent = cookies[INSPIRATIONSZONE_ANALYTICS_CONSENT];

  const [state, setState] = useState({
    cookieConsentHidden: true
  });

  useEffect(() => {
    setTimeout(() => {
      setState({
        cookieConsentHidden: analyticsConsent !== undefined
      });
    }, 500);
  }, [setState, analyticsConsent]);

  const handleAccept = () => {
    // store cookie consent (accept)
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    setCookie(INSPIRATIONSZONE_ANALYTICS_CONSENT, true, {
      expires: oneYearFromNow
    });
    setState({
      ...state,
      cookieConsentHidden: true
    });
    // enable Google Analytics and send page_view event
    window[`ga-disable-${process.env.GA_TRACKING_ID}`] = false;
    window.gtag('event', 'page_view', {
      page_title: title + ' | ' + site.siteMetadata.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
      send_to: process.env.GA_TRACKING_ID
    })
  }

  const handleDecline = () => {
    // store cookie consent (decline)
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    setCookie(INSPIRATIONSZONE_ANALYTICS_CONSENT, false, {
      expires: oneYearFromNow
    });
    setState({
      ...state,
      cookieConsentHidden: true
    });
    // disable Google Analytics (should already be disabled, just making sure :P)
    window[`ga-disable-${process.env.GA_TRACKING_ID}`] = true;
  }

  return (
    <>
      <Header/>
      <SEO title={title}/>
      <main>
        <Container className="text-center text-sm-left">
          {children}
        </Container>
      </main>
      <Footer/>
      <CookieConsent hidden={state.cookieConsentHidden} onAccept={handleAccept} onDecline={handleDecline} onDismiss={handleDecline}/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
}

Layout.propDefaults = {
}

export default Layout
