const linkResolver = (doc) => {
  if (doc.type === 'leistungen') {
    return `/leistungen/${doc.uid}`
  }
  if (doc.type === 'team_detail') {
    return `/team/${doc.uid}`
  }
  if (doc.type === 'leistungen_ubersicht') {
    return `/${doc.uid}`
  }
  if (doc.type === 'prinzipien_ubersicht') {
    return `/${doc.uid}`
  }
  if (doc.type === 'team') {
    return `/${doc.uid}`
  }
  if (doc.type === 'contact') {
    return `/${doc.uid}`
  }
  if (doc.type === 'page') {
    return `/${doc.uid}`
  }
  return '/'
}

module.exports = linkResolver
