import React from "react"
import PropTypes from "prop-types"
import { FaPhoneAlt } from "react-icons/fa"

const Phone = (props) => (
  props.phone && <a href={`tel:${props.phone}`}><FaPhoneAlt className="mr-2"/>{props.phone}</a>
)

Phone.propTypes = {
  phone: PropTypes.string
}

export default Phone;
