import { FaRegEnvelope } from "react-icons/fa"
import React from "react"
import PropTypes from "prop-types"

const Email = (props) => (
  props.email && <a href={`mailto:${props.email}`}><FaRegEnvelope className="mr-2"/>{props.email}</a>
)

Email.propTypes = {
  email: PropTypes.string
}

export default Email
